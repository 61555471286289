module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"default":{"breakpoints":["800px","1200px"],"colors":{"textPrimary":"pink","textSecondary":"#ffffff","white":"#fff","red":"#d76f8b","blue":"#337ab7","green":"#70a644"},"fonts":{"body":"Poppins"},"sectionTitle":{"color":"white","margin":0,"fontFamily":"body","fontSize":"30px","lineHeight":1.5},"heading":{"margin":0,"fontFamily":"body","fontSize":"30px","lineHeight":1.5},"description":{"margin":0,"fontFamily":"body","fontSize":"20px","fontWeight":100,"lineHeight":1.2},"text":{"margin":0,"fontFamily":"body","fontSize":"18px","fontWeight":100,"lineHeight":1.2},"awardName":{"margin":0,"py":"2px","color":"red","fontFamily":"body","fontSize":"12px","fontWeight":100,"lineHeight":1.2,"textAlign":"center"},"eventName":{"margin":0,"py":"2px","color":"green","fontFamily":"body","fontSize":"12px","fontWeight":100,"lineHeight":1.2,"textAlign":"center"},"buttons":{"formButton":{"width":["fit-content","50%"],"height":"40px","backgroundColor":"green","borderRadius":"20px","cursor":"pointer","my":"15px"},"scheduleCall":{"padding":"10px  30px","backgroundColor":"blue","cursor":"pointer"},"link":{"display":"flex","justifyContent":"center","mb":"40px","fontFamily":"body","fontWeight":600,"textAlign":"center","color":"blue","cursor":"pointer"},"caseStudyLink":{"color":"green","background":"transparent","fontWeight":600,"cursor":"pointer","padding":"0"}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
